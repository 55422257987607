export default [
  {
    title: 'Tableau de bord',
    route: 'espace-expert.dashboard',
    icon: 'HomeIcon',
  },
  {
    header: 'Demandes d\'expertise',
    icon: 'PackageIcon',
  },
  {
    title: 'Gestion des demandes',
    icon: 'ListIcon',
    children: [
      {
        title: 'En cours de traitement',
        color: 'info',
        icon: 'DiscIcon',

        route: 'espace-expert.expertises-encours',

      },
      {
        title: 'Demandes traitées',
        color: 'success',
        icon: 'DiscIcon',

        route: 'espace-expert.expertises-traitees',

      },

    ],
  },
  {
    header: 'Gestion de profil',
    icon: 'PackageIcon',
  },
  {
    title: 'Activation de compte',
    icon: 'CheckCircleIcon',
    color: 'success',
    route: 'espace-expert.activate-account',
  },
]
